.utilities-section {
  background-color: $bg;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;
  .wrapper {
    justify-content: space-evenly;
  }
  .utilities-img {
    width: 460px;
    height: 460px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    img {
      height: 230px;
      width: 230px;
      padding: 10px;
      object-fit: cover;
    }
  }

  .utilities-blk {
    text-align: center;
    max-width: 450px;
    ul {
      padding-top: $xxl * 2;
      li {
        color: $txt;
        text-align: left;
        font-size: $large;
        padding-top: $l;
      }
    }
  
    img {
      width: 160px;
    }
  }
}