@font-face {
  font-family: "avenirlight";
  src: url("/fonts/avenir_light-webfont.woff2") format("woff2"),
    url("/fonts/avenir_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "playfair_displayregular";
  src: url("/fonts/playfairdisplay-regular-webfont.woff2") format("woff2"),
    url("/fonts/playfairdisplay-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "work_sansextralight";
  src: url("/fonts/worksans-extralight-webfont.woff2") format("woff2"),
    url("/fonts/worksans-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$titleFmaily: "work_sansextralight";
$subTitleFmaily: "playfair_displayregular";
$txtFamily: "avenirlight";
$subHeaderFamily: "Open Sans";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 22px;
$xxxlarge: 110px;

h1,
h2,
h3,
h4,
h5 {
  color: $atwhite;
}

h1 {
  font-family: $titleFmaily;
  font-size: $xxxlarge;
  font-weight: 100;
}

h2 {
  font-family: $subTitleFmaily;
  font-size: $xxlarge;
}

h4 {
  font-family: $subTitleFmaily;
  font-size: $large;
}

p,
li,
a {
  font-family: $txtFamily;
  font-size: $medium;
  font-weight: 500;
  color: $atwhite;
}

.light {
  font-weight: 500;
}
