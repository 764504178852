.switch-button  {
  background-color: $atblack;
  border-radius: 10px;
  padding: $s;
  padding-left: 0;
  button {
    border-color: $atblue;
    margin-left: $s;
    &.active {
      background-color: $atblue;
      color: $atblack;
    }
  }
}