.intro-section {
  background-color: $bg;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;
  .wrapper {
    justify-content: space-evenly;
  }
  .intro-img {
    width: 605px;
    height: 605px;
    overflow: hidden;
    img {
      height: 605px;
    }
  }

  .intro-blk {
    text-align: center;
    max-width: 450px;
    p {
      color: $txt;
      padding-top: $xxl * 2;
      padding-bottom: $xxl * 2;
      line-height: 24px;
    }

    img {
      width: 160px;
    }
  }
}