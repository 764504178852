.login-buttons  {
  display: flex;
  flex-direction: row;
  button {
    &.metamask {
      background-color: $atblue;
      color: $atblack;
      width: 150px;
    }
  }
  // Avatar
  .login {
    cursor: pointer;
    img {
      width: 40px;
      margin-left: $xl;
    }
  }
}