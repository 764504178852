.cert-section {
  background-color: $bg;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;
  .wrapper {
    justify-content: space-evenly;
  }
  .cert-img {
    width: 605px;
    height: 605px;
    overflow: hidden;
    img {
      height: 605px;
    }
  }

  .cert-blk {
    text-align: center;
    max-width: 450px;
    ul {
      padding-top: $xxl * 2;
      li {
        color: $txt;
        text-align: left;
        font-size: $large;
        padding-top: $l;
      }
    }
  }
}