.home-page {
  
  .mint-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    align-items: center;
    justify-content: flex-start;
    z-index: 5;
    background-image: url('/assets/mint-bg.jpg');
    background-size: cover;
    padding-top: $xxl * 6;
    
    h1 {
      letter-spacing: 16px;
      line-height: 154px;
    }

    h2 {
      font-family: $txtFamily;
      letter-spacing: 8px;
    }

    .mint-blk {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      padding-bottom: $xxl * 3;
      
      button {
        width: 207px;
        &:hover {
          border-radius: 5px;;
          background-color: $hover-green;
          border-color: $hover-green;
          color: $atblack;
        }
      }

      .mint-selection {
        padding: $xxl;
        min-width: 550px;
        min-height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: rgba(173, 169, 160, 0.51);
        .line {
          width: 140px;
          height: 1px;
          display: block;
          background-color: $bg;
          margin: 0 auto;
          margin-top: $xl;
        }

        // Mint num
        .mint-num {
          display: flex;
          flex-direction: row;
          align-items: center;
          button {
            width: 50px;
            height: 50px;
          }
          .mint-num-box {
            h1 {
              font-size: 40px;
              text-align: center;
              padding-left: $xxl + 10px;
              padding-right: $xxl;
            } 
          }
        }

        p {
          padding-top: $xl;
          font-weight: 600;
        }
      }
    }
  }

  @import './intro.scss';
  @import './cert.scss';
  @import './nft-price.scss';
  @import './utilities.scss';
  @import './promo.scss';
  @import './qualifications.scss';
  @import './nft-extra.scss';
  @import './team.scss';
}