$atpink: #BE37EE;
$atblue: #24F2FF;
$atgray: #1A1A1A;
$atlightgray: #A9A9A9;
$atgreen: #10ff9d;
$atwhite: #FFFFFF;
$atblack: #000A16;

$green: #1E5A50;
$white: #F4F3F2;
$hover-green: #CEF0EA;
$bg: #F7F1E0;
$txt: rgb(117, 111, 99);
$altTxt: #F7F1E1