.nft-extra-section {
  background-color: $bg;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;
  .wrapper {
    justify-content: space-evenly;
  }
  .nft-extra-img {
    img {
      height: 553px;
      width: 193px;
      object-fit: cover;
      margin-right: $m;
    }
  }

  .nft-extra-blk {
    text-align: center;
    max-width: 450px;
    p {
      color: $txt;
      padding-top: $xxl * 2;
      padding-bottom: $xxl * 2;
      line-height: 24px;
    }

    img {
      width: 160px;
    }
  }
}