.nft-price-section {
  min-height: 600px;
  background-image: url('/assets/nft-price.jpg');
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  > div{
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: $xxl * 3;
    padding-bottom: $xxl * 3;
    .header {
      h1 {
        color: $atwhite;
        font-weight: 600;
      }
      .line {
        background-color: $atwhite;
        display: flex;
        align-self: flex-end;
        margin: inherit;
        margin-top: $l;
      }
    }
    h1 {
      font-size: 40px;
      font-weight: 600;
    }

    .price-info {
      text-align: right;
      margin-top: $xxl * 1.5;
      h1 {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: $m;
        font-weight: 600;
      }
    }
  }
}